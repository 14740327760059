<template>
    <v-col v-if="showCard" cols="12" sm="6" lg="4">
        <v-card class="primary lighten-1" width="100%" dark>
            <v-card-title class="mx-auto">
                <v-icon large left>mdi-run-fast</v-icon>Speedy Start
            </v-card-title>
            <v-card-text>
                Need a hand getting set up?
            </v-card-text>
            <v-card-actions>
                <v-btn text :to="{ name: 'setup' }">Get Started!</v-btn>
                <v-spacer />
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                            More
                        </v-btn>
                    </template>
                    <v-list max-height="60vh" class="overflow-y-auto">
                        <v-list-item v-for="(c, ind) in custom" :key="ind" :to="{ name: 'setup', query: { step: c.value } }">
                            <v-list-item-icon>
                                <v-icon>{{ c.nav != null ? $BlitzIt.navigation.findIcon(c.nav) : c.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ c.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: 'Setup-Card',
    computed: {
        custom() {
            return [
                { text: 'Categories', nav: 'product-categories', value: 'product-categories' },
                { text: 'Customers', nav: 'customers', value: 'customers' },
                { text: 'Customer Templates', nav: 'customer-templates', value: 'customer-templates' },
                { text: 'Delivery Fees', nav: 'delivery-fees', value: 'delivery-fees' },
                { text: 'Drivers', nav: 'users', value: 'drivers' },
                { text: 'Journeys', nav: 'journey-templates', value: 'journeys' },
                { text: 'Locations', nav: 'locations', value: 'locations' },
                { text: 'Order Pipeline', icon: 'mdi-pipe', value: 'order-pipeline' },
                { text: 'Order Slots', nav: 'order-slots', value: 'order-slots' },
                { text: 'Payment Terms', nav: 'supplier-payment-terms', value: 'supplier-payment-terms' },
                { text: 'Pricing', nav: 'supplier-price-tiers', value: 'supplier-pricing' },
                { text: 'Products', nav: 'products', value: 'products' },
                { text: 'Supply Pointers', nav: 'supply-pointers', value: 'supply-pointers' }
            ];
        },
        showCard() {
            return this.$BlitzIt.auth.session.isGlobalAdmin &&
                (this.$BlitzIt.auth.session?.company?.subscriptionCode == 'SUPP' ||
                this.$BlitzIt.auth.session?.company?.subscriptionCode == 'COUR');
        }
    }
}
</script>